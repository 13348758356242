.about {
  height: 62vh !important;
  width: 100%;
  background: url(../assets/images/pexels-pavel-danilyuk-8381937.jpg) no-repeat center center/cover;
}

.aboutText {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background: #f8f8f8;
}

.aboutDescription {
  width: 50%;
}

.aboutDescription h1 {
  margin: 3rem 0;
}

.aboutText p {
  margin: 1rem 0;
  line-height: 2;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
}

hr {
  display: inline-block;
  width: 50%;
  margin: 2rem 0;
}

@media screen and (max-width:768px) {
  .aboutText {
    padding: 0;
  }

  .aboutDescription {
    width: 80%;
  }

  .aboutDescription h1 {
    font-size: 2.3rem;
  }

  .aboutDescription p {
    font-size: 0.8rem;
  }

  hr {
    margin: 0.8rem 0;
  }
}