.carousel-content {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
}

.carousel-container {
  width: 85%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-container {
  display: flex;
}

.container h1 {
  margin: 3rem 0;
}

.img-container {
  position: relative;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  /* border: 1px solid #333; */
}

.search-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
}

.img-container img {
  height: 50vh;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

}

.img-container:hover img {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.carousel-hover {
  position: absolute;
  top: 70%;
  left: 10;
  width: 100%;
  height: 20%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.img-container:hover .carousel-hover {
  opacity: 1;
  cursor: pointer;
  animation: slide-up 0.5s forwards;
}

.img-container:not(:hover) .carousel-hover {
  animation: slide-down 0.5s forwards;
}

.description {
  margin-top: 1rem;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

/* button  */

.carousel-button {
  position: absolute;
  top: 165%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #333;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1.8rem;
  outline: none;
}

.prev-button {
  left: 3%;
}

.next-button {
  right: 3%;
}

/* Dots  */

.carousel-dots {
  display: flex;
  justify-content: center;
  padding: 1em;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: gray;
  margin: 0 5px;
}

.carousel-dot.active {
  background: black;
}

/* Modal  */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
}

.modal-img {
  max-width: 80%;
  max-height: 80vh;
}

/* keyframes  */

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

/* Responsive  */

@media screen and (max-width: 768px) {
  .carousel-container {
    width: 100%;
  }

  .img-container {
    width: 100%;
  }

  .search-icon {
    display: block;
    font-size: 18px;
  }

  .carousel-button {
    display: none;
  }
}