.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 1rem;
  background-color: rgba(248, 248, 248, 0.9);
  border-top: 1px solid #333;
}

.footer p {
  margin: 0.6rem;
  color: #333;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.footer ul {
  display: flex;
}

.footer ul li {
  margin: 0.6rem;
}

.iconHover {
  transition: 0.4s ease-in-out;
}

.iconHover:hover {
  transform: scale(1.1);
}