*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

li,
a {
    list-style-type: none;
    text-decoration: none;
    color: #333;
}


h1 {
    color: #333;
    font-weight: lighter;
    font-size: 3rem;
    text-decoration: underline solid 1px;
    font-family: 'Great Vibes', sans-serif;
}

.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #333;
    transform-origin: bottom;
    z-index: 1;
}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #333;
    transform-origin: top;
}