.shop-content {
  padding: 2rem;
  text-align: center;
}

.shop-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 6rem 0;
}

h4 {  
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  color: #333;
}

@media screen and (max-width: 768px) {
  .shop-card {
    flex-direction: column;
  }
}