.header {
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.4s ease-in;
    overflow: hidden;
    background-color: rgba(248, 248, 248, 1);
    /* box-shadow: 0 10px 20px rgba(248, 248, 248, 1); */
    border-bottom: 1px solid #333;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: auto;
    height: 100%;
    padding: 0 2rem;
}

.header .logo {
    font-size: 2.5rem;
    font-family: 'Great Vibes', cursive;
    letter-spacing: 1px;
    text-decoration: underline 1px solid;
    cursor: pointer;
}

.hamburger {
    display: none;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.header .nav-item a {
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
}

.header .nav-item a:hover {
    /* color: #333; */
    padding-bottom: 0.4rem;
    border-bottom: 1px solid #333;
}

/* Responsive  */

@media screen and (max-width: 940px) {
    .header {
        max-width: 100%;
    }

    .header .navbar {
        max-width: 100%;
    }

    .header .logo {
        font-size: 1.6rem;
        font-weight: bold;
    }

    .header .nav-item a {
        color: #333;
        font-weight: bold;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 80px;
        flex-direction: column;
        background-color: rgba(248, 248, 248, 1);
        width: 100%;
        height: 75vh;
        z-index: 999;
        text-align: center;
        transition: 0.3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem;
    }
}