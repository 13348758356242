.containerForm {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/images/pexels-dzenina-lukac-1469902.jpg) no-repeat center center/cover;
}

.contact {
  width: 80%;
  margin: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: rgba(248, 248, 248, 0.9);
  border-radius: 3px;
}

.contact-txt {
  width: 50%;
  padding: 1rem;
  text-align: center;
}

.contact-txt h1 {
  margin: 2rem;
}

.contact-txt p {
  margin: 1.8rem;
  font-family: 'Poppins', sans-serif;
}

.contact-form {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  margin: 0rem 0rem 1.5rem 0rem;
  padding: 1rem;
  background-color: rgba(248, 248, 248, 1);
  border-radius: 3px;
}

.contact-form input,
.contact-form textarea {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.contact-form button {
  border: 1px solid rgba(0, 0, 0, 0.7);
}

.contact-form button:hover {
  opacity: 0.7;
}

.contact-form textarea {
  min-height: 100px !important;
}

.contact-form [placeholder] {
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 768px) {
  .containerForm {
    height: 100%;
  }

  .contact {
    width: 100%;
    flex-direction: column;
    margin: 0;
  }

  .contact-txt {
    width: 100%;
    padding: 0;
  }

  .contact-txt h1 {
    margin: 1rem;
    font-size: 2.3rem;
  }

  .contact-txt p {
    margin: 0.8rem;
    font-size: 0.8rem;
  }

  .contact-form {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}