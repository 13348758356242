.section-home {
  color: #fff;
  height: 100vh !important;
  width: 100%;
  background: url(../assets/images/background-home.jpg) no-repeat center center/cover !important;
}

.section-ht {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url(../assets/images/pexels-cottonbro-studio-3779077.jpg) no-repeat center center/cover;
}

.container-ht {
  width: 70%;
  margin: 5rem 0;
  padding: 1rem;
  border-radius: 3px;
  background-color: rgba(248, 248, 248, 0.9);
}

.container-ht h1 {
  margin: 4rem;
}

.container-ht p {
  margin: 2rem 6rem;
  line-height: 2;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 768px) {
  .container-ht {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .container-ht h1 {
    margin: 2rem;
  }

  .container-ht P {
    margin: 1rem 1rem;
    font-size: 0.8rem;
  }
}

